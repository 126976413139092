import React, { useState, useEffect } from "react";
import {
	TextField,
	Typography,
	Button,
	Radio,
	RadioGroup,
	FormControlLabel,
	FormControl,
	Container,
	Grid,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	TableHead,
} from "@mui/material";
import moment from "moment";
import { Add, Remove } from "@mui/icons-material";
import { calculateGrossWeight, calculateGrossWeight1, calculateTotalWeight, calculateTdsAmount, calculateTcsAmount, calculateNewPayable, calculateRoundOff } from "./bullionCalculations";

function BullionCalculator() {
	const [rate, setRate] = useState(undefined);
	const [rows, setRows] = useState([{ weight: "", touch: "", tokara: "", purity: 99.5, pureWeight: "" }]);
	const [rows1, setRows1] = useState([{ weight: "", rate: "", amount: "" }]);
	const [tds, setTds] = useState(0.1);
	const [calculatorType, setCalculatorType] = useState("tds");
	const [customerName, setCustomerName] = useState("");
	const [token, setToken] = useState("");
	const [grossWeight, setGrossWeight] = useState("");
	const [totalAmount, setTotalAmount] = useState(0);
	const [tdsAmount, setTdsAmount] = useState(0);
	const [tcsAmount, setTcsAmount] = useState(0);
	const [retwght, setRetwght] = useState(undefined);
	const [roff, setRoff] = useState(0);
	const [payable, setPayable] = useState("");
	const [recd, setRecd] = useState("");
	const [balance, setBalance] = useState(0);
	const [manualPayableChange, setManualPayableChange] = useState(false);

	const handleRowChange = (index, field, value) => {
		const newRows = [...rows];
		newRows[index][field] = parseFloat(value) || undefined;

		if (calculatorType === "kacha") {
			if (field === "touch" || field === "tokara") {
				const touch = newRows[index].touch;
				const tokara = newRows[index].tokara;
				newRows[index].purity = touch > tokara ? touch - tokara : 0;
			}
		} else {
			const weight = isNaN(parseFloat(newRows[index].weight)) ? 0 : parseFloat(newRows[index].weight);
			const purity = isNaN(parseFloat(newRows[index].purity)) ? 0 : parseFloat(newRows[index].purity);

			newRows[index].pureWeight = isNaN(parseFloat((weight * purity) / 100)) ? "" : (weight * purity) / 100;
		}

		setRows(newRows);
		if (field !== "rate") setManualPayableChange(false);
	};

	const handleRow1Change = (index, field, value) => {
		const newRows = [...rows1];
		newRows[index][field] = parseFloat(value) || undefined;

		const weight = isNaN(parseFloat(newRows[index].weight)) ? 0 : parseFloat(newRows[index].weight);
		const rate = isNaN(parseFloat(newRows[index].rate)) ? 0 : parseFloat(newRows[index].rate);

		newRows[index].amount = isNaN(parseFloat(weight * rate)) ? "" : weight * rate;

		setRows1(newRows);
	};

	const handleClearRow = () => {
		setRows([{ weight: "", touch: "", tokara: "", purity: 99.5, pureWeight: "" }]);
	};

	const handleAddRow = () => {
		setRows([...rows, { weight: "", touch: "", tokara: "", purity: 99.5, pureWeight: "" }]);
	};

	const handleRemoveRow = (index) => {
		if (rows.length <= 1) return;
		const newRows = rows.filter((_, i) => i !== Number(isNaN(parseInt(index)) ? rows.length - 1 : index));
		setRows(newRows);
	};

	const handleClearRow1 = () => {
		setRows1([{ weight: "", rate: "", amount: "" }]);
	};

	const handleAddRow1 = () => {
		setRows1([...rows1, { weight: "", rate: "", amount: "" }]);
	};

	const handleRemoveRow1 = (index) => {
		if (rows1.length <= 1) return;
		const newRows = rows1.filter((_, i) => i !== index);
		setRows1(newRows);
	};

	const handlePayableChange = (e) => {
		setPayable(e.target.value === 0 ? "" : e.target.value);
		setManualPayableChange(true);
	};

	const handleRecdChange = (e) => {
		setRecd(e.target.value);
	};

	const handleRateChange = (e) => {
		setRate(e.target.value);
	};

	const handleReturnChange = (e) => {
		setRetwght(e.target.value);
		setManualPayableChange(false);
	};

	const totalWeight = calculateTotalWeight(rows);
	const totWgt = calculateGrossWeight(rows);

	useEffect(() => {
		if (manualPayableChange && rows.length === 1) {
			let calculatedTdsAmount = 0;
			let calculatedTcsAmount = 0;
			let newTotalAmount = 0;

			if (calculatorType === "tds") {
				const gstAmount = (payable * 3) / (100 + 3);
				const payableBeforeGST = payable - gstAmount;

				calculatedTdsAmount = payableBeforeGST * (tds / 100);
				newTotalAmount = Number(payable) + Number(calculatedTdsAmount);
			} else if (calculatorType === "tcs") {
				calculatedTcsAmount = newTotalAmount * (1 + tds / 100);
				newTotalAmount = payable * (1 + tds / 100);
			} else {
				newTotalAmount = payable;
			}
			setTotalAmount(newTotalAmount);

			setTdsAmount(calculatedTdsAmount);
			setTcsAmount(calculatedTcsAmount);
			setRoff(calculateRoundOff(Number(calculatedTdsAmount) + Number(calculatedTcsAmount) + Number(newTotalAmount)));

			setRows((prevRows) =>
				prevRows.map((row) => {
					let weight = undefined;
					if (calculatorType === "tds") {
						weight = newTotalAmount / (rate * (row.purity / 100));
					} else if (calculatorType === "tcs") {
						weight = newTotalAmount / (rate * (row.purity / 100));
					}
					const purity = isNaN(parseFloat(row.purity)) ? 0 : parseFloat(row.purity);
					setGrossWeight(isNaN(parseFloat(weight)) ? "" : weight.toFixed(3));

					return {
						...row,
						weight: weight,
						pureWeight: isNaN(parseFloat((weight * purity) / 100)) ? "" : (weight * purity) / 100,
					};
				})
			);
		} else {
			if (parseFloat(totWgt) < parseFloat(grossWeight) && isNaN(parseFloat(rows[rows.length - 1]?.weight)) === false) handleAddRow();
			if (parseFloat(totWgt) >= parseFloat(grossWeight) && isNaN(parseFloat(rows[rows.length - 1]?.weight)) && rows.length > 1) handleRemoveRow(rows.length - 1);
		}
		setBalance(payable - recd);
	}, [rows, calculatorType, totWgt, totalWeight, tds, manualPayableChange, payable, recd, grossWeight, rate]);

	const totWgt1 = calculateGrossWeight1(rows1);

	useEffect(() => {
		if (parseFloat(totWgt1) < parseFloat(totalWeight) && isNaN(parseFloat(rows1[rows1.length - 1]?.weight)) === false) handleAddRow1();
		if (parseFloat(totWgt1) >= parseFloat(totalWeight) && isNaN(parseFloat(rows1[rows1.length - 1]?.weight)) && rows1.length > 1) handleRemoveRow1(rows1.length - 1);

		if (!manualPayableChange) {
			const totalAmountForAllRows = rows1.reduce((total, row) => {
				const amount = row.amount === "" ? 0 : row.amount;
				return total + parseFloat(amount);
			}, 0);

			setTotalAmount(totalAmountForAllRows);

			const calculatedTdsAmount = calculateTdsAmount(totalAmountForAllRows, tds, calculatorType);
			const calculatedTcsAmount = calculateTcsAmount(totalAmountForAllRows, tds, calculatorType);

			setTdsAmount(calculatedTdsAmount);
			setTcsAmount(calculatedTcsAmount);

			const newPayable = calculateNewPayable(totalAmountForAllRows, calculatedTdsAmount, calculatedTcsAmount, calculatorType);

			setRoff(calculateRoundOff(newPayable));
			if (newPayable !== 0 && !isNaN(parseFloat(newPayable))) setPayable(Math.round(newPayable).toFixed(2));
		}
		setBalance(payable - recd);
	}, [rows1, calculatorType, totWgt, tds, recd]);

	const handleClear = () => {
		setTds(0.1);
		setCustomerName("");
		setTotalAmount(0);
		setTdsAmount(0);
		setTcsAmount(0);
		setRetwght(undefined);
		setRoff(0);
		setPayable("");
		setRecd("");
		setBalance(0);
		setManualPayableChange(false);
		handleClearRow();
		handleClearRow1();
	};

	return (
		<Container maxWidth="sm">
			<Paper
				elevation={3}
				style={{
					padding: "2px",
					spacing: "2px",
					backgroundColor: calculatorType === "tds" ? "#f0f8ff" : calculatorType === "tcs" ? "#fffacd" : calculatorType === "kacha" ? "#e6ffe6" : "#ffffff",
				}}
				className="print-paper"
			>
				<Typography variant="h5" align="center" gutterBottom className="print-visible">
					ESTIMATE
				</Typography>
				<Typography variant="h5" align="center" gutterBottom className="print-hidden">
					PAMBIAH BULLION
				</Typography>
				<FormControl component="fieldset" className="print-hidden">
					<RadioGroup
						row
						value={calculatorType}
						onChange={(e) => setCalculatorType(e.target.value)}
						style={{
							padding: "0",
							spacing: "0",
						}}
					>
						<FormControlLabel value="tds" control={<Radio />} label="TDS" />
						<FormControlLabel value="tcs" control={<Radio />} label="TCS" />
						<FormControlLabel value="none" control={<Radio />} label="None" />
						<FormControlLabel value="kacha" control={<Radio />} label="Kacha" />
					</RadioGroup>
				</FormControl>
				{calculatorType !== "none" && (
					<Typography variant="h6" className="print-visible">
						{calculatorType.toUpperCase()}
					</Typography>
				)}
				<Grid container spacing={1} padding={1} className="print-hidden">
					<Grid item xs={12}>
						<TextField
							label="Customer Name"
							variant="outlined"
							fullWidth
							value={customerName}
							onChange={(e) => setCustomerName(e.target.value)}
							placeholder="Enter customer name"
							size="small"
							onFocus={(e) => e.target.select()}
						/>
					</Grid>
				</Grid>
				<Grid container spacing={0} padding={0} className="print-visible">
					<Grid item xs={12}>
						<Typography variant="body1" align="right">
							Dt. {moment().format("DD-MMM-YYYY HH:mm")}
						</Typography>
					</Grid>
				</Grid>
				<Grid container spacing={0} padding={0} className="print-visible">
					<Grid item xs={12}>
						<Typography variant="h7" align="left">
							Customer: {customerName}
						</Typography>
					</Grid>
				</Grid>
				<Grid container spacing={1} padding={1} className="print-hidden">
					<Grid item xs={12}>
						<TextField
							label="Token No"
							variant="outlined"
							fullWidth
							value={token}
							onChange={(e) => setToken(e.target.value)}
							placeholder="Enter Token No"
							size="small"
							onFocus={(e) => e.target.select()}
						/>
					</Grid>
				</Grid>
				<Grid container spacing={0} padding={0} className="print-visible">
					<Grid item xs={12}>
						<Typography variant="h7" align="left">
							Token No: {token}
						</Typography>
					</Grid>
				</Grid>
				{calculatorType !== "kacha" && !manualPayableChange && (
					<>
						<Grid container spacing={1} padding={1} className="print-hidden">
							<Grid item xs={12}>
								<TextField
									label="Total Weight"
									variant="outlined"
									fullWidth
									value={grossWeight}
									onChange={(e) => setGrossWeight(e.target.value)}
									placeholder="Enter Total Weight"
									size="small"
									onFocus={(e) => e.target.select()}
								/>
							</Grid>
						</Grid>
					</>
				)}
				<TableContainer style={{ marginTop: "1px" }}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell sx={{ spacing: 0, padding: 0 }} align="right">
									<b>Weight(g)</b>
								</TableCell>
								{calculatorType === "kacha" && (
									<>
										<TableCell sx={{ spacing: 0, padding: 0 }} align="right">
											<b>Touch(%)</b>
										</TableCell>
										<TableCell sx={{ spacing: 0, padding: 0 }} align="right">
											<b>Tokara(%)</b>
										</TableCell>
									</>
								)}
								<TableCell sx={{ spacing: 0, padding: 0 }} align="right">
									<b>Purity(%)</b>
								</TableCell>
								{calculatorType !== "kacha" && (
									<TableCell sx={{ spacing: 0, padding: 0 }} align="right" width={100}>
										<b>Pure(g)</b>
									</TableCell>
								)}
							</TableRow>
						</TableHead>
						<TableBody>
							{rows.map((row, index) => {
								return (
									<TableRow key={index}>
										<TableCell sx={{ spacing: 0, padding: 0 }} align="right">
											{manualPayableChange ? (
												<Typography variant="body1">{isNaN(parseFloat(row.weight)) ? "" : parseFloat(row.weight).toFixed(3)}</Typography>
											) : (
												<>
													<TextField
														label=""
														variant="outlined"
														fullWidth
														type="number"
														value={row.weight}
														onChange={(e) => handleRowChange(index, "weight", e.target.value)}
														size="small"
														onFocus={(e) => e.target.select()}
														InputProps={{
															inputProps: {
																style: { textAlign: "right" },
															},
															classes: {
																input: "no-arrows",
															},
														}}
														className="print-hidden"
													/>
													<Typography variant="body1" className="print-visible">
														{isNaN(parseFloat(row.weight)) ? "" : parseFloat(row.weight).toFixed(3)}
													</Typography>
												</>
											)}
										</TableCell>
										{calculatorType === "kacha" && (
											<>
												<TableCell sx={{ spacing: 0, padding: 0 }} align="right">
													<TextField
														label=""
														variant="outlined"
														fullWidth
														type="number"
														value={row.touch}
														onChange={(e) => handleRowChange(index, "touch", e.target.value)}
														size="small"
														onFocus={(e) => e.target.select()}
														InputProps={{
															inputProps: {
																style: { textAlign: "right" },
															},
															classes: {
																input: "no-arrows",
															},
														}}
														className="print-hidden"
													/>
													<Typography variant="body1" className="print-visible">
														{isNaN(parseFloat(row.touch)) ? "" : parseFloat(row.touch).toFixed(2)}
													</Typography>
												</TableCell>
												<TableCell sx={{ spacing: 0, padding: 0 }} align="right">
													<TextField
														label=""
														variant="outlined"
														fullWidth
														type="number"
														value={row.tokara}
														onChange={(e) => handleRowChange(index, "tokara", e.target.value)}
														size="small"
														onFocus={(e) => e.target.select()}
														InputProps={{
															inputProps: {
																style: { textAlign: "right" },
															},
															classes: {
																input: "no-arrows",
															},
														}}
														className="print-hidden"
													/>
													<Typography variant="body1" className="print-visible">
														{isNaN(parseFloat(row.tokara)) ? "" : parseFloat(row.tokara).toFixed(2)}
													</Typography>
												</TableCell>
												<TableCell sx={{ spacing: 0, padding: 0 }} align="right">
													<Typography variant="body1">{isNaN(parseFloat(row.purity)) ? "" : parseFloat(row.purity).toFixed(2)}</Typography>
												</TableCell>
											</>
										)}
										{calculatorType !== "kacha" && (
											<>
												<TableCell sx={{ spacing: 0, padding: 0 }} align="right">
													<TextField
														label=""
														variant="outlined"
														fullWidth
														type="number"
														value={row.purity}
														onChange={(e) => handleRowChange(index, "purity", e.target.value)}
														size="small"
														onFocus={(e) => e.target.select()}
														InputProps={{
															inputProps: {
																style: { textAlign: "right" },
															},
															classes: {
																input: "no-arrows",
															},
														}}
														className="print-hidden"
													/>
													<Typography variant="body1" className="print-visible">
														{isNaN(parseFloat(row.purity)) ? "" : parseFloat(row.purity).toFixed(2)}
													</Typography>
												</TableCell>
												<TableCell sx={{ spacing: 0, padding: 0 }} align="right">
													<Typography variant="body1">{isNaN(parseFloat(row.pureWeight)) ? "" : parseFloat(row.pureWeight).toFixed(3)}</Typography>
												</TableCell>
											</>
										)}
									</TableRow>
								);
							})}
							{calculatorType === "kacha" && (
								<TableRow className="print-hidden">
									<TableCell colSpan={2}>
										<Button variant="outlined" startIcon={<Add />} fullWidth onClick={handleAddRow}>
											Add
										</Button>
									</TableCell>
									<TableCell colSpan={2}>
										<Button variant="outlined" startIcon={<Remove />} fullWidth onClick={handleRemoveRow}>
											Remove
										</Button>
									</TableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<TableContainer>
					<Table>
						<TableBody>
							<TableRow>
								<TableCell align="left" sx={{ spacing: 1, padding: 1 }}>
									<b>Total Pure</b>
								</TableCell>
								<TableCell align="right" sx={{ spacing: 1, padding: 1 }}>
									<b>{isNaN(parseFloat(totalWeight)) ? "" : totalWeight}</b>
								</TableCell>
							</TableRow>
							{calculatorType !== "kacha" && !manualPayableChange && (
								<TableRow style={{ width: "100%" }}>
									<TableCell style={{ width: "100%" }} align="left" colSpan={2} sx={{ spacing: 0, padding: 0 }}>
										<TableContainer>
											<Table>
												<TableHead>
													<TableRow>
														<TableCell sx={{ spacing: 0, padding: 0 }} align="right">
															<b>Rate(₹)</b>
														</TableCell>
														<TableCell sx={{ spacing: 0, padding: 0 }} align="right">
															<b>Weight(g)</b>
														</TableCell>
														<TableCell sx={{ spacing: 0, padding: 0 }} align="right">
															<b>Amount(₹)</b>
														</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{rows1.map((row, index) => {
														return (
															<TableRow key={index}>
																<TableCell sx={{ spacing: 0, padding: 0 }} align="right" width={80}>
																	<TextField
																		label=""
																		variant="outlined"
																		fullWidth
																		type="number"
																		value={row.rate}
																		onChange={(e) => handleRow1Change(index, "rate", e.target.value)}
																		size="small"
																		onFocus={(e) => e.target.select()}
																		InputProps={{
																			inputProps: {
																				style: { textAlign: "right" },
																			},
																			classes: {
																				input: "no-arrows",
																			},
																		}}
																		className="print-hidden"
																	/>
																	<Typography variant="body1" className="print-visible">
																		{isNaN(parseFloat(row.rate)) ? "" : parseFloat(row.rate).toFixed(2)}
																	</Typography>
																</TableCell>
																<TableCell sx={{ spacing: 0, padding: 0 }} align="right" width={80}>
																	<TextField
																		label=""
																		variant="outlined"
																		fullWidth
																		type="number"
																		value={row.weight}
																		onChange={(e) => handleRow1Change(index, "weight", e.target.value)}
																		size="small"
																		onFocus={(e) => e.target.select()}
																		InputProps={{
																			inputProps: {
																				style: { textAlign: "right" },
																			},
																			classes: {
																				input: "no-arrows",
																			},
																		}}
																		className="print-hidden"
																	/>
																	<Typography variant="body1" className="print-visible">
																		{isNaN(parseFloat(row.weight)) ? "" : parseFloat(row.weight).toFixed(3)}
																	</Typography>
																</TableCell>
																<TableCell sx={{ spacing: 0, padding: 0 }} align="right" width={155}>
																	<Typography variant="body1">{isNaN(parseFloat(row.amount)) ? "" : parseFloat(row.amount).toFixed(2)}</Typography>
																</TableCell>
															</TableRow>
														);
													})}
												</TableBody>
											</Table>
										</TableContainer>
									</TableCell>
								</TableRow>
							)}
							{calculatorType !== "kacha" && (
								<>
									{manualPayableChange && (
										<TableRow>
											<TableCell align="left" sx={{ spacing: 1, padding: 1 }}>
												Rate
											</TableCell>
											<TableCell align="right" sx={{ spacing: 1, padding: 1 }}>
												<TextField
													label=""
													variant="outlined"
													fullWidth
													type="number"
													value={rate}
													onChange={handleRateChange}
													className="print-hidden"
													size="small"
													onFocus={(e) => e.target.select()}
													InputProps={{
														inputProps: {
															style: { textAlign: "right" },
														},
														classes: {
															input: "no-arrows",
														},
													}}
												/>
												<Typography variant="body1" className="print-visible">
													₹{parseFloat(rate).toFixed(2)}
												</Typography>
											</TableCell>
										</TableRow>
									)}
									<TableRow>
										<TableCell align="left" sx={{ spacing: 1, padding: 1 }}>
											Net Amount
										</TableCell>
										<TableCell align="right" sx={{ spacing: 1, padding: 1 }}>
											₹{parseFloat(totalAmount).toFixed(2)}
										</TableCell>
									</TableRow>
									{tdsAmount + tcsAmount > 0 && (
										<TableRow>
											<TableCell align="left" sx={{ spacing: 1, padding: 1 }}>
												Total {calculatorType === "tds" ? "TDS" : "TCS"}
											</TableCell>
											<TableCell align="right" sx={{ spacing: 1, padding: 1 }}>
												₹{calculatorType === "tds" ? parseFloat(tdsAmount).toFixed(2) : parseFloat(tcsAmount).toFixed(2)}
											</TableCell>
										</TableRow>
									)}
									<TableRow>
										<TableCell align="left" sx={{ spacing: 1, padding: 1 }}>
											Round Off
										</TableCell>
										<TableCell align="right" sx={{ spacing: 1, padding: 1 }}>
											₹{roff.toFixed(2)}
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell align="left" width={100} sx={{ spacing: 1, padding: 1 }}>
											<h3>Payable</h3>
										</TableCell>
										{rows.length > 1 ? (
											<TableCell align="right" sx={{ spacing: 1, padding: 1 }}>
												<Typography variant="h5">₹{parseFloat(payable).toFixed(0)}</Typography>
											</TableCell>
										) : (
											<TableCell align="right" sx={{ spacing: 1, padding: 1 }}>
												<TextField
													label=""
													variant="outlined"
													fullWidth
													type="number"
													value={payable}
													onChange={handlePayableChange}
													size="small"
													onFocus={(e) => e.target.select()}
													InputProps={{
														inputProps: {
															style: { textAlign: "right" },
														},
														classes: {
															input: "no-arrows",
														},
													}}
													className="print-hidden"
												/>
												<Typography variant="h5" className="print-visible">
													₹{parseFloat(payable).toFixed(0)}
												</Typography>
											</TableCell>
										)}
									</TableRow>
									<TableRow>
										<TableCell align="left" sx={{ spacing: 1, padding: 1 }}>
											Received
										</TableCell>
										<TableCell align="right" sx={{ spacing: 1, padding: 1 }}>
											<TextField
												label=""
												variant="outlined"
												fullWidth
												type="number"
												value={recd}
												onChange={handleRecdChange}
												size="small"
												onFocus={(e) => e.target.select()}
												InputProps={{
													inputProps: {
														style: { textAlign: "right" },
													},
													classes: {
														input: "no-arrows",
													},
												}}
												className="print-hidden"
											/>
											<Typography className="print-visible">₹{isNaN(parseFloat(recd)) ? "" : parseFloat(recd).toFixed(0)}</Typography>
										</TableCell>
									</TableRow>
									{recd > 0 && (
										<TableRow>
											<TableCell align="left" sx={{ spacing: 1, padding: 1 }}>
												Balance(₹)
											</TableCell>
											<TableCell align="right" sx={{ spacing: 1, padding: 1 }}>
												<Typography>₹{isNaN(parseFloat(balance)) ? "" : parseFloat(balance).toFixed(0)}</Typography>
											</TableCell>
										</TableRow>
									)}
								</>
							)}
							{calculatorType === "kacha" && (
								<>
									<TableRow>
										<TableCell align="left" sx={{ spacing: 1, padding: 1 }}>
											Return
										</TableCell>
										<TableCell align="right" sx={{ spacing: 1, padding: 1 }}>
											<TextField
												label=""
												variant="outlined"
												fullWidth
												type="number"
												value={retwght}
												onChange={handleReturnChange}
												className="print-hidden"
												size="small"
												onFocus={(e) => e.target.select()}
												InputProps={{
													inputProps: {
														style: { textAlign: "right" },
													},
													classes: {
														input: "no-arrows",
													},
												}}
											/>
											<Typography variant="body1" className="print-visible">
												{isNaN(parseFloat(retwght)) ? "" : parseFloat(retwght).toFixed(3)}
											</Typography>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell align="left" sx={{ spacing: 1, padding: 1 }}>
											Balance(g)
										</TableCell>
										<TableCell align="right" sx={{ spacing: 1, padding: 1 }}>
											<Typography variant="h5">{isNaN(parseFloat(totalWeight) - parseFloat(retwght)) ? "" : (parseFloat(totalWeight) - parseFloat(retwght)).toFixed(3)}</Typography>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell align="left" sx={{ spacing: 1, padding: 1 }}>
											Rate
										</TableCell>
										<TableCell align="right" sx={{ spacing: 1, padding: 1 }}>
											<TextField
												label=""
												variant="outlined"
												fullWidth
												type="number"
												value={rate}
												onChange={handleRateChange}
												className="print-hidden"
												size="small"
												onFocus={(e) => e.target.select()}
												InputProps={{
													inputProps: {
														style: { textAlign: "right" },
													},
													classes: {
														input: "no-arrows",
													},
												}}
											/>
											<Typography variant="body1" className="print-visible">
												₹{isNaN(parseFloat(rate)) ? "" : parseFloat(rate).toFixed(2)}
											</Typography>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell align="left" sx={{ spacing: 1, padding: 1 }}>
											Round Off
										</TableCell>
										<TableCell align="right" sx={{ spacing: 1, padding: 1 }}>
											{isNaN((parseFloat(totalWeight) - parseFloat(retwght)) * parseFloat(rate))
												? ""
												: calculateRoundOff((parseFloat(totalWeight) - parseFloat(retwght)) * parseFloat(rate)).toFixed(2)}
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell align="left" sx={{ spacing: 1, padding: 1 }}>
											Balance(₹)
										</TableCell>
										<TableCell align="right" sx={{ spacing: 1, padding: 1 }}>
											<Typography variant="h5">
												{isNaN((parseFloat(totalWeight) - parseFloat(retwght)) * parseFloat(rate)) ? "" : Math.round((parseFloat(totalWeight) - parseFloat(retwght)) * parseFloat(rate)).toFixed(2)}
											</Typography>
										</TableCell>
									</TableRow>
								</>
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<Grid container spacing={2} className="print-hidden">
					<Grid item xs={6}>
						<Button variant="contained" color="secondary" fullWidth onClick={handleClear}>
							Clear
						</Button>
					</Grid>
					<Grid item xs={6}>
						<Button variant="contained" color="primary" fullWidth onClick={() => window.print()}>
							Print
						</Button>
					</Grid>
				</Grid>
			</Paper>
			<style jsx global>{`
				@media print {
					body {
						display: flex;
						justify-content: center;
						height: 100vh;
						margin: 0;
						padding: 0;
						visibility: visible;
					}
					.print-paper,
					.print-paper * {
						visibility: visible;
					}
					.print-paper {
						width: 3in;
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						padding: 0;
						margin: 0.1;
						box-sizing: border-box;
					}
					.print-hidden {
						display: none !important;
					}
					.print-visible {
						display: block !important;
					}
				}
				.print-visible {
					display: none;
				}
				.no-arrows::-webkit-outer-spin-button,
				.no-arrows::-webkit-inner-spin-button {
					-webkit-appearance: none;
					margin: 0;
				}

				/* Firefox */
				.no-arrows[type="number"] {
					-moz-appearance: textfield;
				}
			`}</style>
		</Container>
	);
}

export default BullionCalculator;
