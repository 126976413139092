export const calculateGrossWeight = (rows) => {
	return rows
		.reduce((sum, row) => {
			const weight = parseFloat(row.weight);
			return sum + (isNaN(weight) ? 0 : weight);
		}, 0)
		.toFixed(3);
};

export const calculateGrossWeight1 = (rows) => {
	return rows
		.reduce((sum, row) => {
			const weight = parseFloat(row.weight);
			return sum + (isNaN(weight) ? 0 : weight);
		}, 0)
		.toFixed(3);
};

export const calculateTotalWeight = (rows) => {
	return rows
		.reduce((sum, row) => {
			const weight = isNaN(row.weight) ? 0 : parseFloat(row.weight);
			const purity = isNaN(row.purity) ? 100 : parseFloat(row.purity);
			return sum + weight * (purity / 100);
		}, 0)
		.toFixed(3);
};

export const calculateTotalAmount = (totalWeight, rate) => {
	return (totalWeight * rate).toFixed(2);
};

export const calculateTdsAmount = (totalAmount, tds, calculatorType) => {
	return calculatorType === "tds" ? (((totalAmount / 1.03) * tds) / 100).toFixed(2) : 0;
};

export const calculateTcsAmount = (totalAmount, tds, calculatorType) => {
	return calculatorType === "tcs" ? ((totalAmount * tds) / 100).toFixed(2) : 0;
};

export const calculateNewPayable = (totalAmount, tdsAmount, tcsAmount, calculatorType) => {
	return calculatorType === "tds" ? (Number(totalAmount) - Number(tdsAmount)).toFixed(2) : (Number(totalAmount) + Number(tcsAmount)).toFixed(2);
};

export const calculateRoundOff = (newPayable) => {
	return Math.round(newPayable) - newPayable;
};
